import { EventType } from '../enums';
import { IEvent } from '@studyportals/event-aggregation-service-interface';

export class ProfileQuestionnairesCPIFilters implements IEvent {
	public static EventType: string = EventType.PROFILE_QUESTIONNAIRES_CPI_FILTERS;
	public readonly timestamp: Date;
	public readonly eventType: string = ProfileQuestionnairesCPIFilters.EventType;

	public readonly filters: {
		key: string;
		value: string;
	}[];

	public constructor(filters: { key: string; value: string }[]) {
		this.filters = filters;
		this.timestamp = new Date();
	}
}
