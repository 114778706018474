import { QuestionType } from './../enums/question-type';
import { QuestionnaireType } from './../enums/questionnaire-type';

export class RenderQuestionnaireCommand extends CustomEvent<RenderProfileQuestionnaireRequest> {
	constructor(identifier: QuestionnaireType, showOnRender = true, isPopupQuestionnaire = false, data?: { steps: QuestionType[] }) {
		const detail: RenderProfileQuestionnaireRequest = {
			identifier,
			showOnRender,
			isPopupQuestionnaire,
		};

		if (data) {
			detail.data = data;
		}

		super('', { detail });
	}
}

interface RenderProfileQuestionnaireRequest {
	identifier: string;
	showOnRender: boolean;
	isPopupQuestionnaire: boolean;
	data?: { steps?: QuestionType[] };
}
