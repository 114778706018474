import { Incentives } from '../../interfaces/enums/incentives';
import { QuestionnaireType } from '../../interfaces/enums/questionnaire-type';
import { computed, ref, Ref } from 'vue';
import store from '@/store';
import globals from '@/utils/globals';

export default class App {
	public showIncentive = ref(false);
	public showQuestionnaire = ref(false);

	public requestedSteps: Ref<string[] | null> = ref(null);

	public questionnaires: typeof QuestionnaireType = QuestionnaireType;

	public incentives: typeof Incentives = Incentives;

	public isReady = computed((): boolean => {
		return store.getters.isReady();
	});

	public questionnaireType = computed((): string => {
		return String(this.questionnaireIdentifier);
	});

	public incentiveType = computed((): string => {
		return String(this.incentiveIdentifier);
	});

	public questionnaireIndexNumber = computed((): number => {
		const index = this.questionnaireIndex;

		if (index === null || typeof index === 'undefined') {
			return 0;
		}
		return Number(this.questionnaireIndex);
	});

	constructor(
		private questionnaireIdentifier?: string,
		private questionnaireIndex?: number,
		private questionnaireSteps?: string,
		private rootShowQuestionnaire?: boolean,
		public incentiveIdentifier?: string,
	) {}

	public onStepsChanged(): void {
		this.showQuestionnaire.value = this.rootShowQuestionnaire ?? false;
	}

	public created(): void {
		if (this.incentiveIdentifier) {
			this.showIncentive.value = true;
			return;
		}
		this.saveQuestionnaireSteps();
		this.registerInitializeEvent();
		this.closeQuestionnaireOnFinish();
	}

	public closeQuestionnaireOnFinish(): void {
		globals.eventBus.on('questionnaireFinished', (identifier) => {
			if (identifier === this.questionnaireType.value) {
				this.showQuestionnaire.value = false;
			}
		});
	}

	public registerInitializeEvent(): void {
		document.addEventListener('profile_questionnaires_initialize', (event: any) => {
			const identifier = event.detail.identifier;
			const data = event.detail.data;
			if (typeof identifier === 'undefined') {
				return;
			}
			if (identifier !== this.questionnaireType.value) {
				return;
			}

			this.requestedSteps.value = this.getStepsFromPayload(data);
			this.showQuestionnaire.value = true;
		});
	}

	public saveQuestionnaireSteps(): void {
		const steps = this.questionnaireSteps;
		if (steps === null || typeof steps === 'undefined') {
			this.requestedSteps.value = [];
			return;
		}

		this.requestedSteps.value = steps.replace(' ', '').split(',');
	}

	public getStepsFromPayload(payload): string[] | null {
		if (typeof payload === 'undefined' || payload === null) {
			return [];
		}
		if (typeof payload.steps === 'undefined') {
			return [];
		}
		return payload.steps as string[];
	}
}
