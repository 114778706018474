import { ProfileQuestionnairesReadyEvent, ProfileQuestionnairesCpiModalClosed } from './../interfaces/events';
import { ProfileQuestionnairesApplication } from './application/profile-questionnaires-application';

import App from './presentation/App.vue';
import { createApp, h } from 'vue';
import { ModalManager, SimpleModal } from '@studyportals/modal';
import { DependencyCheckerPlugin } from './store/plugins/dependency-checker-plugin';
import GlobalsPlugin from './utils/vue-globals';
import { Modal } from '@studyportals/modal/src/domain/modal';
import { IUProgamData } from './interfaces/iu-program-data.interface';
import { EventAggregationService } from './infrastructure/event-aggregation-service';
import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import globals from './utils/globals';
import { StudyPreferencesEventTrackType } from './interfaces/enums/study-preferences-event-track-type';
import { QuestionnaireType } from 'interfaces';

declare global {
	interface Window {
		EventAggregationService: IEventAggregationService | null | undefined;
		rollbar?: typeof Rollbar;
		rollbarIgnore: (...args) => boolean;
		Shared: Shared;
		snowplow?: typeof snowplow;
		hj?: typeof hj;
	}
}

class Main {
	private readonly eventAggregationService = new EventAggregationService();
	private modals: Modal[] = [];
	public modalManager = new ModalManager();

	public initialize(): void {
		const dependencyCheckerPlugin = new DependencyCheckerPlugin();
		dependencyCheckerPlugin.initialize();

		this.addProfileQuestionnairesInitializaListener();

		this.dispatchProfileQuestionnairesReadyEvent();

		const application = this.createProfileQuestionnairesApplication();

		this.publishPqReadyEventToEventAggregationService(application);
	}

	private addProfileQuestionnairesInitializaListener(): void {
		document.addEventListener('profile_questionnaires_initialize', (event: any) => {
			const identifier = event.detail.identifier as string;
			const showOnRender = event.detail.showOnRender as boolean;
			const data = event.detail.data;
			const isPopupQuestionnaire = event.detail.isPopupQuestionnaire as boolean;

			if (typeof identifier !== 'undefined') {
				main.renderQuestionnaire(identifier, showOnRender, isPopupQuestionnaire, data);
			}
		});
	}

	private publishPqReadyEventToEventAggregationService(application: ProfileQuestionnairesApplication): void {
		this.eventAggregationService.publishTo(ProfileQuestionnairesReadyEvent.EventType, new ProfileQuestionnairesReadyEvent(application));
	}

	private dispatchProfileQuestionnairesReadyEvent(): void {
		window['profile_questionnaires_ready'] = true;
		document.dispatchEvent(new Event('profile_questionnaires_ready'));
	}

	private createProfileQuestionnairesApplication(): ProfileQuestionnairesApplication {
		const application = new ProfileQuestionnairesApplication();
		window['ProfileQuestionnairesApplication'] = application;
		return application;
	}

	private getIuData(node: Element): IUProgamData {
		const credits = node.getAttribute('data-credits');
		return {
			education_level: node.getAttribute('data-education_level') ?? '',
			degree_type: node.getAttribute('data-degree_type') ?? '',
			delivery_method: node.getAttribute('data-delivery_method') ?? '',
			credits: this.parseCredits(credits),
		};
	}

	private parseCredits(credits: string | null): number | undefined {
		if (credits === null || Number.isNaN(+credits)) {
			return undefined;
		}
		return +credits;
	}

	public renderQuestionnaire(identifier: string, showOnRender = true, isPopUpQuestionnaire = false, questionnaireData: any): void {
		if (isPopUpQuestionnaire) {
			this.opensPopupQuestionnaireModal(identifier);
		}

		const nodes: NodeListOf<Element> = document.querySelectorAll('.ProfileQuestionnaire');

		for (const node of Array.from(nodes)) {
			const questionnaireIdentifier: string | null = node.getAttribute('data-questionnaire');
			const incentiveIdentifier: string | null = node.getAttribute('data-incentive');

			if (questionnaireIdentifier === identifier) {
				this.mountQuestionnaire(node, showOnRender, questionnaireData, questionnaireIdentifier);
			}

			if (incentiveIdentifier === identifier) {
				this.mountIncentive(node, incentiveIdentifier);
			}
		}
	}

	private mountIncentive(node: Element, incentiveIdentifier: string): void {
		const incentiveData = this.getIuData(node);

		const props = {
			incentive_identifier: incentiveIdentifier ?? null,
			data: incentiveData,
		};
		this.createAndMountApp(props, node);
	}

	private mountQuestionnaire(node: Element, showOnRender = true, questionnaireData: any, questionnaireIdentifier: string): void {
		const questionnaireIndex = node.getAttribute('data-index') as string;
		const questionnaireSteps = this.getQuestionnaireSteps(questionnaireData, node);

		const props = {
			questionnaire_identifier: questionnaireIdentifier ?? null,
			questionnaire_index: questionnaireIndex ?? null,
			questionnaire_steps: questionnaireSteps ?? null,
			showQuestionnaire: showOnRender,
			data: questionnaireData,
		};
		this.createAndMountApp(props, node);
	}

	private getQuestionnaireSteps(questionnaireData: any, node: Element): string {
		if (!this.getStepsFromPayload(questionnaireData)) {
			return node.getAttribute('data-steps') as string;
		}

		return this.getStepsFromPayload(questionnaireData) ?? '';
	}

	private createAndMountApp(props: object, node: Element): void {
		const profileQuestionnairesApp = createApp({
			render: () => {
				return h(App, props);
			},
		});
		profileQuestionnairesApp.use(GlobalsPlugin);
		profileQuestionnairesApp.mount(node);
	}

	public opensPopupQuestionnaireModal(identifier: string): void {
		this.createPopQuestionnaire(identifier);

		this.modalManager.open(this.modals[identifier] as Modal);
	}

	private createPopQuestionnaire(identifier: string): void {
		const placeholder = this.createPlaceholderForModal(identifier);

		this.createModal(identifier, placeholder);

		this.addQuestionnaireEventListenerToClosePopUp(identifier, 'questionnaire_completed');
		this.addQuestionnaireEventListenerToClosePopUp(identifier, 'questionnaire_closed');
	}

	private createPlaceholderForModal(identifier: string): HTMLDivElement {
		const placeholder = document.createElement('div');
		placeholder.classList.add('ProfileQuestionnaire');
		placeholder.setAttribute('data-questionnaire', identifier);
		return placeholder;
	}

	private createModal(identifier: string, placeholder: HTMLDivElement): void {
		this.modals[identifier] = new SimpleModal(placeholder, {
			cssClassName: 'QuestionnairePopup',
			destroyOnClose: true,
			onClose: (): void => this.onModalClose(identifier as QuestionnaireType),
		});
	}

	private onModalClose(identifier: QuestionnaireType): void {
		if (identifier !== QuestionnaireType.STUDY_PREFERENCES) return;

		this.eventAggregationService.publishTo(ProfileQuestionnairesCpiModalClosed.EventType, new ProfileQuestionnairesCpiModalClosed());
		globals.snowplowTracker.trackCPIRegistration(StudyPreferencesEventTrackType.CLOSE);
	}

	private addQuestionnaireEventListenerToClosePopUp(identifier: string, eventIdentifier: string): void {
		document.addEventListener(eventIdentifier, (e: any) => {
			this.closePopup(e, identifier);
		});
	}

	private getStepsFromPayload(payload: any): string | null {
		const steps = payload?.steps as object | undefined | null;
		if (!steps) {
			return null;
		}

		return steps.toString();
	}

	private closePopup(e: any, identifier: string): void {
		if (e.detail.identifier === identifier) {
			this.modalManager.close(this.modals[identifier] as Modal);
		}
	}
}

const main = new Main();
export default main;
main.initialize();
