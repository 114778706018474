import { IEvent } from '@studyportals/event-aggregation-service-interface';
import { EventType } from '../enums';

export class ProfileQuestionnairesCpiModalClosed implements IEvent {
	public static EventType: string = EventType.PROFILE_QUESTIONNAIRES_CPI_MODAL_CLOSED;
	public readonly timestamp: Date;
	public readonly eventType: string = ProfileQuestionnairesCpiModalClosed.EventType;

	public constructor() {
		this.timestamp = new Date();
	}
}
