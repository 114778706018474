import { defineComponent, watch, defineAsyncComponent } from 'vue';
import App from './App.class';

export default defineComponent({
	props: {
		questionnaire_identifier: String ?? null,
		questionnaire_index: Number ?? null,
		questionnaire_steps: String ?? null,
		showQuestionnaire: Boolean,
		incentive_identifier: String,
		data: Object,
	},
	components: {
		BestFit: defineAsyncComponent(() => import('./components/Questionnaire/Questionnaires/BestFit/BestFit.vue')),
		BestFitSearch: defineAsyncComponent(() => import('./components/Incentives/BestFitSearch/BestFitSearch.vue')),
		GlobalAccessInitiativeLabel: defineAsyncComponent(
			() => import('./components/Incentives/GlobalAccessInitiativeLabel/GlobalAccessInitiativeLabel.vue'),
		),
		GlobalAccessInitiativeBanner: defineAsyncComponent(
			() => import('./components/Incentives/GlobalAccessInitiativeBanner/GlobalAccessInitiativeBanner.vue'),
		),
		GlobalAccessInitiative: defineAsyncComponent(
			() => import('./components/Questionnaire/Questionnaires/GlobalAccessInitiative/GlobalAccessInitiative.vue'),
		),
		LocalizationSettings: defineAsyncComponent(
			() => import('./components/Questionnaire/Questionnaires/LocalizationSettings/LocalizationSettings.vue'),
		),
		Onboarding: defineAsyncComponent(() => import('./components/Questionnaire/Questionnaires/Onboarding/Onboarding.vue')),
		ProfilePage: defineAsyncComponent(() => import('./components/Questionnaire/Questionnaires/ProfilePage/ProfilePage.vue')),
		SearchGlobalAccessInitiativeLabel: defineAsyncComponent(
			() => import('./components/Incentives/SearchGlobalAccessInitiativeLabel/SearchGlobalAccessInitiativeLabel.vue'),
		),
		Recommendations: defineAsyncComponent(() => import('./components/Questionnaire/Questionnaires/Recommendations/Recommendations.vue')),
		ScholarshipIncentiveLabel: defineAsyncComponent(
			() => import('./components/Incentives/ScholarshipIncentiveLabel/ScholarshipIncentiveLabel.vue'),
		),
		SearchScholarshipIncentiveLabel: defineAsyncComponent(
			() => import('./components/Incentives/SearchScholarshipIncentiveLabel/SearchScholarshipIncentiveLabel.vue'),
		),
		StartDate: defineAsyncComponent(() => import('./components/Questionnaire/Questionnaires/StartDate/StartDate.vue')),
		ScholarshipsEligibility: defineAsyncComponent(
			() => import('./components/Questionnaire/Questionnaires/ScholarshipsEligibility/ScholarshipsEligibility.vue'),
		),
		Discover: defineAsyncComponent(() => import('./components/Questionnaire/Questionnaires/FullJourneySupport/Discover/Discover.vue')),
		StudyPreferences: defineAsyncComponent(() => import('./components/Questionnaire/Questionnaires/StudyPreferences/StudyPreferences.vue')),
	},
	setup: (props) => {
		const component = new App(
			props.questionnaire_identifier,
			props.questionnaire_index,
			props.questionnaire_steps,
			props.showQuestionnaire,
			props.incentive_identifier,
		);

		watch(component.requestedSteps, component.onStepsChanged.bind(component));
		component.created();
		return {
			incentiveType: component.incentiveType,
			isReady: component.isReady,
			showIncentive: component.showIncentive,
			incentives: component.incentives,
			steps: component.requestedSteps,
			showQuestionnaire: component.showQuestionnaire,
			questionnaireType: component.questionnaireType,
			questionnaires: component.questionnaires,
			questionnaireIndexNumber: component.questionnaireIndexNumber,
			data: props.data,
		};
	},
});
