import { RenderQuestionnaireIncentiveCommand } from './../../interfaces/commands/render-questionnaire-incentive-command';
import { RenderQuestionnaireCommand } from './../../interfaces/commands/render-questionnaire-command';
import Main from '@/main';
import { IProfileQuestionnairesApplication } from './../../interfaces/profile-questionnaires-application.interface.';

export class ProfileQuestionnairesApplication implements IProfileQuestionnairesApplication {
	public renderQuestionnaire(command: RenderQuestionnaireCommand): void {
		Main.renderQuestionnaire(
			command.detail.identifier,
			command.detail.showOnRender,
			command.detail.isPopupQuestionnaire,
			command.detail.data,
		);
	}

	public renderIncentive(command: RenderQuestionnaireIncentiveCommand): void {
		Main.renderQuestionnaire(command.detail.identifier, true, false, null);
	}
}
