import { StudyPreferencesAttendanceType, StudyPreferencesEventTrackType } from '@/interfaces/enums/study-preferences-event-track-type';
import { StudyPreferencesFieldType } from '@/interfaces/enums/study-preferences-filed-type';
import { Product, Action, Tracker } from '@studyportals/datalake-event-tracker';
//TODO: this should be fixed in the package
import { ITrackingData, IExperimentTrackingData } from '@studyportals/datalake-event-tracker/dist/src/interfaces/tracking-data.interface';
import { Incentives, QuestionnaireType } from 'interfaces';

export default class SnowplowTracker {
	private tracker: Tracker;

	constructor() {
		this.tracker = new Tracker(Product.PROFILE_QUESTIONNAIRES);
	}

	public trackStepImpression(questionnaire: QuestionnaireType, step?: string): void {
		const data: ITrackingData = {
			action: Action.IMPRESSION,
			label: `${questionnaire}_step`,
			property: step,
		};

		this.tracker.trackStructuredEvent(data);
	}

	public trackIncentiveImpression(incentive: Incentives | string): void {
		const data: ITrackingData = {
			action: Action.IMPRESSION,
			label: `incentive`,
			property: incentive,
		};

		this.tracker.trackStructuredEvent(data);
	}

	public trackOnboardingHelpfulContentClick(property: string): void {
		const data: ITrackingData = {
			action: Action.CLICK,
			label: `${QuestionnaireType.ONBOARDING}_helpful_content`,
			property: this.convertStringToSnakeCase(property),
		};

		this.tracker.trackStructuredEvent(data);
	}

	private convertStringToSnakeCase(unformattedString: string): string {
		return unformattedString
			.replace(/\W+/g, ' ')
			.split(/ |\B(?=[A-Z])/)
			.map((word) => word.toLowerCase())
			.join('_');
	}

	public trackCPIRegistration(
		label: StudyPreferencesEventTrackType,
		property?: StudyPreferencesFieldType | StudyPreferencesAttendanceType | string,
	): void {
		const data: IExperimentTrackingData = {
			category: Product.EXPERIMENT,
			action: Action.CLICK,
			label: `${QuestionnaireType.STUDY_PREFERENCES}_${label}`,
			property,
		};

		this.tracker.trackExperimentEvent(data);
	}
}
