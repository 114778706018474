export enum QuestionType {
	EDUCATIONAL_LEVEL = 'study_level',
	INSTITUTE_OF_ORIGIN = 'institute_of_origin',
	FIELD_OF_STUDY = 'disciplines',
	DISCIPLINE_INTERESTS = 'discipline_interests',
	COUNTRY_INTERESTS = 'country_interests',
	START_PERIOD = 'start_period',
	GPA = 'gpa',
	WORK_EXPERIENCE = 'work_experience',
	ENGLISH_SKILLS = 'englishproficiency',
	TUITION_BUDGET = 'tuition_budget',
	LIVING_BUDGET = 'living_budget',
	NATIONALITY = 'nationality_country_id',
	COUNTRY_OF_RESIDENCE = 'residence_country_id',
	EMAIL = 'email',
	TELEPHONE = 'telephone',
	BIRTH_DATE = 'birth_date',
	SAVE_PROGRESS = 'create_account',
	FIND_BACHELORS = 'academic_highschool',
	INTRODUCTION = 'introduction',
	GENDER = 'gender',
	NAME = 'name',
	ATTENDANCE = 'attendance',
	CURRENCY = 'currency',
}
