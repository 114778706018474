import { IQuestionnaireSteps } from './../interfaces/questionnaire-steps.interface';
import { NavigationDirections } from '../domain/enums/navigation-directions';
import { NavigationStates } from '../domain/enums/navigation-states';
import store from '.';
import { IGetters } from '../interfaces/store.interfaces';
import { QuestionType } from 'interfaces/enums/question-type';
import { ICurrentStep } from '@/interfaces/current-step.interface';
import { QuestionDTO } from '@/interfaces/answer.interface';

const getters: IGetters = {
	isReady: () => store.state.isReady,
	questionKeys: () => Object.keys(store.state.questions) as QuestionType[],
	isLoggedIn: () => store.state.isLoggedIn,
	profileCompleteness: () => store.state.profileCompleteness,
	profileCompletenessFirstUnansweredQuestion: () => store.state.profileCompletenessFirstUnansweredQuestion,
	questionnaires: (identifier) => {
		if (typeof store.state.questionnaires[identifier] === 'undefined') {
			return null;
		}
		return store.state.questionnaires[identifier];
	},
	answerToQuestion: <TAnswer>(identifier: string): TAnswer | null => {
		if (typeof store.state.questions[identifier] === 'undefined') {
			return null;
		}
		const question: QuestionDTO<TAnswer> = store.state.questions[identifier];
		return question.answer;
	},
	questions: <TAnswer>(type: QuestionType) => store.state.questions[type] as QuestionDTO<TAnswer>,
	currentStep: (identifier: string): ICurrentStep => {
		if (!(identifier in store.state.questionnaires)) {
			return {
				currentStepKey: '0',
				currentStepLabel: '',
				index: 0,
				currentStepDarkBackground: false,
			};
		}

		const index: number = store.state.questionnaires[identifier].index;
		const steps: IQuestionnaireSteps[] | null = store.state.questionnaires[identifier].steps;
		if (!steps || !steps.length) {
			return {
				currentStepKey: '0',
				currentStepLabel: '',
				index: 0,
				currentStepDarkBackground: false,
			};
		}
		const currentStep: IQuestionnaireSteps = steps[index];

		if (typeof currentStep === 'undefined') {
			return {
				currentStepKey: '0',
				currentStepLabel: '',
				index: 0,
				currentStepDarkBackground: false,
			};
		}

		const currentStepKey = currentStep.key;
		const currentStepLabel = currentStep.label;
		const currentStepDarkBackground = currentStep.darkBackground;

		return { currentStepKey, currentStepLabel, index, currentStepDarkBackground };
	},

	navigationDirection: (identifier: string): string => {
		if (typeof store.state.questionnaires[identifier] === 'undefined') {
			return NavigationDirections.FORWARD;
		}
		return store.state.questionnaires[identifier].direction;
	},

	onlyFinishOnSignIn: (identifier: string): boolean => {
		if (typeof store.state.questionnaires[identifier] === 'undefined') {
			return false;
		}
		return store.state.questionnaires[identifier].onlyFinishOnSignIn;
	},

	questionnaireProgress: (identifier: string): number => {
		const index = store.state.questionnaires[identifier].index;
		let relativeIndex = 0;
		let totalAmountOfShownQuestions = 0;
		const steps = store.state.questionnaires[identifier]?.steps;
		if (!steps) {
			return 0;
		}
		steps.forEach((step, count) => {
			if (step.show === true) {
				if (count === index) {
					relativeIndex = totalAmountOfShownQuestions;
				}
				totalAmountOfShownQuestions++;
			}
		});

		const progress = Math.ceil((100 * relativeIndex) / totalAmountOfShownQuestions);

		if (!Number.isInteger(progress) || relativeIndex === 0 || totalAmountOfShownQuestions <= 0) {
			return 0;
		}

		return progress;
	},

	isLastStep: (identifier: string): boolean => {
		const steps = store.state.questionnaires[identifier].steps;
		let lastStep = {
			key: '-1',
			show: false,
		};

		const currentStep = store.getters.currentStep(identifier);

		steps?.forEach((step) => {
			if (step.show === true) {
				lastStep = step;
			}
		});

		return currentStep.currentStepKey === lastStep.key;
	},

	isInQuestionnaire: (questionnaire: string, stepLabel: string): boolean => {
		const steps = store.state.questionnaires[questionnaire].steps;
		let isInQuestionnaire = false;

		steps?.forEach((step) => {
			if (step.show === true && step.label === stepLabel) {
				isInQuestionnaire = true;
			}
		});

		return isInQuestionnaire;
	},

	isFinished: (identifier: string): boolean => {
		if (typeof store.state.questionnaires[identifier] === 'undefined') {
			return false;
		}
		return store.state.questionnaires[identifier]?.isFinished;
	},

	navigationState: (identifier: string): NavigationStates => {
		return store.state.questionnaires[identifier]?.navigationState;
	},

	stepIndexByType: (identifier: string, questionType: string): number => {
		let stepIndex = 0;
		store.state.questionnaires[identifier]?.steps?.forEach((step, key) => {
			if (step.label === questionType && step.show) {
				stepIndex = key;
			}
		});

		return stepIndex;
	},

	userCurrency: () => store.state.userCurrency,

	userLocalCurrency: () => store.state.userLocalCurrency,

	iuCountryId: () => store.state.iuCountryId,

	globalAccessInitiativeQuestionnaireTaken: () => store.state.globalAccessInitiativeQuestionnaireTaken,

	currentSelectedStudyPreferenceField: () => store.state.currentSelectedStudyPreferenceField,

	userNationalityIsoCode: () => store.state.userNationalityIsoCode,
};

export default getters;
