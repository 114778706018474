import { IAnonymousStudentService } from '@studyportals/anonymous-student-interfaces';
import { StudentDataChangedEventListener } from '../../domain/event-listeners/student-data-changed-event-listener';
import { ProfileCompletenessRepository } from '../../domain/repositories/profile-completeness-repository';
import { StudentRepository } from '../../domain/repositories/student-repository';
import { StudentDatabaseClient } from '../../infrastructure/student-database-client';
import store from '../';
import globals from '@/utils/globals';

export class StudentQuestionsAnswersPlugin {
	private studentRepository?: StudentRepository;
	private studentDataChangedEventListener?: StudentDataChangedEventListener;

	public async initialize(): Promise<void> {
		const anonymousStudent: IAnonymousStudentService = globals.anonymousStudent;

		const studentDatabaseClient = new StudentDatabaseClient(anonymousStudent);
		this.studentRepository = new StudentRepository(studentDatabaseClient);

		globals.studentDatabaseClient = studentDatabaseClient;
		globals.studentRepository = this.studentRepository;
		globals.profileCompletenessRepository = new ProfileCompletenessRepository(anonymousStudent);

		this.studentDataChangedEventListener = this.createStudentDataChangedEventListener();
		this.studentDataChangedEventListener.startListening();
		await this.updateStudentInformation();
	}

	private async updateStudentInformation(): Promise<void> {
		await store.actions.updateUserCurrency();
		await store.actions.updateUserLocalCurrency();
		await store.actions.updateAllQuestions();
	}

	private createStudentDataChangedEventListener(): StudentDataChangedEventListener {
		return (this.studentDataChangedEventListener = new StudentDataChangedEventListener(this.studentRepository!));
	}
}
