import { AnonymousStudentServiceReady } from '@studyportals/anonymous-student-interfaces';
import { SessionServiceReadyEvent } from '@studyportals/student-interfaces';
import store from '../';
import globals from '@/utils/globals';
import { ISubscriber } from '@studyportals/event-aggregation-service-interface/bin/src/ISubscriber';
export class DependencyCheckerPlugin implements ISubscriber<SessionServiceReadyEvent | AnonymousStudentServiceReady> {
	private anonymousStudentIsReady = false;
	private sessionServiceIsReady = false;

	public initialize(): void {
		const eventAggregationService = window['EventAggregationService'];
		if (!eventAggregationService) {
			return;
		}

		globals.eventAggregationService = eventAggregationService;

		eventAggregationService.subscribeTo(SessionServiceReadyEvent.EventType, this, true);
		eventAggregationService.subscribeTo(AnonymousStudentServiceReady.EventType, this, true);
	}

	public async notify(event: SessionServiceReadyEvent | AnonymousStudentServiceReady): Promise<void> {
		if (event.eventType === SessionServiceReadyEvent.EventType) {
			this.handleSessionServiceReady(event as SessionServiceReadyEvent);
		}

		if (event.eventType === AnonymousStudentServiceReady.EventType) {
			this.handleAnonymousStudentReady(event as AnonymousStudentServiceReady);
		}

		if (this.sessionServiceIsReady && this.anonymousStudentIsReady) {
			await store.actions.setAreDependenciesLoaded(true);
		}
	}

	private handleSessionServiceReady(event: SessionServiceReadyEvent): void {
		globals.sessionService = event.sessionService;
		this.sessionServiceIsReady = true;
	}

	private handleAnonymousStudentReady(event: AnonymousStudentServiceReady): void {
		globals.anonymousStudent = event.anonymousStudentService;
		this.anonymousStudentIsReady = true;
	}
}
