import { PortalType } from './../interfaces/enums/portal-type';
import { ProfileCompletenessRepository } from './../domain/repositories/profile-completeness-repository';
import { StudentRepository } from '@/domain/repositories/student-repository';
import { StudentDatabaseClient } from './../infrastructure/student-database-client';
import { IAnonymousStudentService } from '@studyportals/anonymous-student-interfaces';
import { ISessionService } from '@studyportals/student-interfaces';
import { IOption, IOptionGroup } from '../../node_modules/@studyportals/multiselect/src/interfaces/options.interface';
import mitt from 'mitt';
import Tracking from './tracking';
import actions from '@/dictionary/tracking/actions';
import categories from '@/dictionary/tracking/categories';
import { ICountryGroupOption, ICountryOption } from '@/interfaces/i-country-option';
import SnowplowTracker from './snowplow-tracker';
import HotJarTracker from './hotjar-tracker';
import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
const eventBus = mitt();

const getNestedOptionByValue = (
	value: string | number | null,
	groups: IOptionGroup[] | ICountryGroupOption[],
): IOption | ICountryOption | null => {
	let foundOption: IOption | null = null;

	groups.forEach((group) => {
		const foundOptions = group.options.filter((option: IOption) => {
			return option.value === value;
		});

		if (foundOptions?.length > 0) {
			foundOption = foundOptions[0];
		}
	});
	return foundOption;
};

const getOptionByValue = (value: string | number | null, options: IOption[]): IOption | null => {
	const foundOptions: IOption[] = options.filter((option) => option.value === value);
	if (foundOptions.length !== 1) {
		return null;
	}
	return foundOptions[0];
};

const snowplowTracker = new SnowplowTracker();

const tracking = new Tracking();

const trackingDictionary = {
	actions,
	categories,
};
// set in the student questions answers plugin
const studentDatabaseClient: StudentDatabaseClient = {} as StudentDatabaseClient;
const studentRepository: StudentRepository = {} as StudentRepository;
const profileCompletenessRepository: ProfileCompletenessRepository = {} as ProfileCompletenessRepository;

// set in dependency checker plugin
const sessionService: ISessionService = {} as ISessionService;
const eventAggregationService: IEventAggregationService = {} as IEventAggregationService;
const anonymousStudent: IAnonymousStudentService = {} as IAnonymousStudentService;
const portalType: PortalType = (window['PortalType'] as PortalType) ?? PortalType.MASTERS;

const hotjar = new HotJarTracker();
const tagHotjarRecording = hotjar.tagHotjarRecording.bind(this);
const trackHotjarEvent = hotjar.trackHotjarEvent.bind(this);

export default {
	eventBus,
	getNestedOptionByValue,
	getOptionByValue,
	tagHotjarRecording,
	trackHotjarEvent,
	snowplowTracker,
	tracking,
	trackingDictionary,
	profileCompletenessRepository,
	studentRepository,
	studentDatabaseClient,
	sessionService,
	eventAggregationService,
	anonymousStudent,
	portalType,
};
