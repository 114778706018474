import { IProfileQuestionnairesApplication } from './../profile-questionnaires-application.interface.';
import { EventType } from '../enums';
import { IEvent } from '@studyportals/event-aggregation-service-interface';

export class ProfileQuestionnairesReadyEvent implements IEvent {
	public static EventType: string = EventType.PROFILE_QUESTIONNAIRES_READY_EVENT;

	public readonly timestamp: Date;
	public readonly eventType = ProfileQuestionnairesReadyEvent.EventType;
	public profileQuestionnairesApplication: IProfileQuestionnairesApplication;

	constructor(public _profileQuestionnairesApplication: IProfileQuestionnairesApplication) {
		this.profileQuestionnairesApplication = _profileQuestionnairesApplication;
		this.timestamp = new Date();
	}
}
