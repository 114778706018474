import {
	AnonymousStudentProfileSynced,
	AnonymousStudentProfileUpdated,
	StudentRepositoryStateType,
} from '@studyportals/anonymous-student-interfaces';
import { StudentField } from '@studyportals/studentdomain';
import { QuestionType } from '../../../interfaces/enums/question-type';
import { StudentRepository } from '../repositories/student-repository';
import store from '../../store';
import { EventAggregationService } from '../../infrastructure/event-aggregation-service';
import { IEventAggregationService, ISubscriber } from '@studyportals/event-aggregation-service-interface';

export class StudentDataChangedEventListener implements ISubscriber<AnonymousStudentProfileUpdated | AnonymousStudentProfileSynced> {
	constructor(private studentRepository: StudentRepository) {}

	public startListening(): void {
		const eventAggregationService = this.getEventAggregationService();
		eventAggregationService.subscribeTo(AnonymousStudentProfileUpdated.EventType, this);
		eventAggregationService.subscribeTo(AnonymousStudentProfileSynced.EventType, this);
	}

	public async notify(event: AnonymousStudentProfileUpdated | AnonymousStudentProfileSynced): Promise<void> {
		if (event.eventType === AnonymousStudentProfileUpdated.EventType) {
			await this.handleAnonymousStudentProfileChangedEvent(event as AnonymousStudentProfileUpdated);
		}

		if (event.eventType === AnonymousStudentProfileSynced.EventType) {
			await this.handleAnonymousStudentProfileSynced(event as AnonymousStudentProfileSynced);
		}
	}

	private async handleAnonymousStudentProfileChangedEvent(event: AnonymousStudentProfileUpdated): Promise<void> {
		const updatedFields = Object.keys(event.changes) as StudentField[];
		const questionTypesToUpdate = this.getQuestionsToUpdateFromEvent(updatedFields);

		if (questionTypesToUpdate.length > 0) {
			await store.actions.updateUserCurrency();
			await store.actions.updateUserLocalCurrency();
			await store.actions.updateUserNationalityIsoCode();
			await this.updateQuestions(questionTypesToUpdate);
			await store.actions.updateProfileCompleteness();
		}
	}

	private async handleAnonymousStudentProfileSynced(event: AnonymousStudentProfileSynced): Promise<void> {
		if (event.state === StudentRepositoryStateType.ONLINE) {
			await store.actions.updateAllQuestions();
			await store.actions.updateUserCurrency();
			await store.actions.updateUserLocalCurrency();
			await store.actions.updateUserNationalityIsoCode();
		}
	}

	private getQuestionsToUpdateFromEvent(updatedFields: StudentField[]): QuestionType[] {
		const questionTypes = store.getters.questionKeys();
		const questionTypesToBeUpdated = new Set<QuestionType>();

		questionTypes.forEach((type) => {
			const questionFields = this.studentRepository.translateQuestionTypeToStudentFields(type);
			if (updatedFields.some((field) => questionFields.includes(field))) {
				questionTypesToBeUpdated.add(type);
			}
		});

		return Array.from(questionTypesToBeUpdated);
	}

	private async updateQuestions(questionTypes: QuestionType[]): Promise<void> {
		const questions = await this.studentRepository.retrieveQuestions(questionTypes);
		store.actions.updateQuestions(questions);
	}

	private getEventAggregationService(): IEventAggregationService {
		return new EventAggregationService();
	}
}
