import { IStudent, StudentField } from '@studyportals/studentdomain';
import { EnglishProficiencyType } from '../enums/english-proficiency-type';
import { EnglishTestType } from '../enums/english-test-type';
import { QuestionType } from '../../../interfaces/enums/question-type';
import {
	Answer,
	IAttendance,
	IBirthDate,
	ICountryInterests,
	ICountryOfResidence,
	ICurrency,
	IDisciplineInterests,
	IDisciplines,
	IEducationalLevel,
	IEmail,
	IEnglishLevel,
	IGender,
	IGPA,
	IInstituteOfOrigin,
	ILivingBudget,
	IName,
	INationality,
	IStartPeriod,
	ITuitionBudget,
	IWorkExperience,
} from '../../interfaces/answer.interface';

export class AnswerFactory {
	public createAnswerFromStudentData(type: QuestionType, studentData: IStudent): Answer {
		switch (type) {
			case QuestionType.WORK_EXPERIENCE:
				return this.createWorkExperienceAnswer(studentData);
			case QuestionType.TUITION_BUDGET:
				return this.createTuitionBudgetAnswer(studentData);
			case QuestionType.START_PERIOD:
				return this.createStartPeriodAnswer(studentData);
			case QuestionType.NATIONALITY:
				return this.createNationalityAnswer(studentData);
			case QuestionType.LIVING_BUDGET:
				return this.createLivingBudgetAnswer(studentData);
			case QuestionType.INSTITUTE_OF_ORIGIN:
				return this.createInstituteOfOriginAnswer(studentData);
			case QuestionType.GPA:
				return this.createGPAAnswer(studentData);
			case QuestionType.FIELD_OF_STUDY:
				return this.createFieldOfStudyAnswer(studentData);
			case QuestionType.ENGLISH_SKILLS:
				return this.createEnglishSkillsAnswer(studentData);
			case QuestionType.EMAIL:
				return this.createEmailAnswer(studentData);
			case QuestionType.EDUCATIONAL_LEVEL:
				return this.createEducationalLevelAnswer(studentData);
			case QuestionType.DISCIPLINE_INTERESTS:
				return this.createDisciplineInterestAnswer(studentData);
			case QuestionType.COUNTRY_OF_RESIDENCE:
				return this.createCountryOfResidenceAnswer(studentData);
			case QuestionType.COUNTRY_INTERESTS:
				return this.createCountryInterestAnswer(studentData);
			case QuestionType.BIRTH_DATE:
				return this.createBirthDateAnswer(studentData);
			case QuestionType.GENDER:
				return this.createGenderAnswer(studentData);
			case QuestionType.NAME:
				return this.createNameAnswer(studentData);
			case QuestionType.ATTENDANCE:
				return this.createAttendanceAnswer(studentData);
			case QuestionType.CURRENCY:
				return this.createCurrencyAnswer(studentData);
			default:
				throw new Error('Not implemented');
		}
	}

	private createWorkExperienceAnswer(studentData: IStudent): IWorkExperience {
		return {
			work_experience: studentData[StudentField.WORK_EXPERIENCE] || '',
		};
	}

	private createTuitionBudgetAnswer(studentData: IStudent): ITuitionBudget {
		return {
			tuition_budget: studentData.tuition_budget || {
				amount: null,
				unit: 'year',
			},
		};
	}

	private createStartPeriodAnswer(studentData: IStudent): IStartPeriod {
		return {
			start_period_date: studentData[StudentField.START_PERIOD_DATE] || '',
		};
	}

	private createNationalityAnswer(studentData: IStudent): INationality {
		return {
			nationality_country_id: studentData[StudentField.NATIONALITY_COUNTRY_ID] || null,
			nationality_country_iso: studentData[StudentField.NATIONALITY_COUNTRY_ISO] || null,
		};
	}

	private createLivingBudgetAnswer(studentData: IStudent): ILivingBudget {
		return {
			living_budget: studentData.living_budget || {
				amount: null,
				unit: 'month',
			},
		};
	}

	private createInstituteOfOriginAnswer(studentData: IStudent): IInstituteOfOrigin {
		return {
			origin_organisation_id: studentData.origin_organisation_id || '',
			study_country_id: studentData.study_country_id || null,
		};
	}

	private createGPAAnswer(studentData: IStudent): IGPA {
		if (!studentData.gpa || !studentData.gpa['current_type']) {
			return {
				gpa: { current_type: '' },
			};
		}

		const currentType = studentData.gpa['current_type'];

		return {
			gpa: {
				current_type: currentType,
				[currentType]: studentData.gpa[currentType],
			},
		};
	}

	private createFieldOfStudyAnswer(studentData: IStudent): IDisciplines {
		return {
			disciplines: studentData.disciplines || [],
		};
	}

	private createEnglishSkillsAnswer(studentData: IStudent): IEnglishLevel {
		return {
			cae: studentData.cae ? Number(studentData.cae) : undefined,
			ielts: studentData.ielts ? Number(studentData.ielts) : undefined,
			pte: studentData.pte ? Number(studentData.pte) : undefined,
			toefl_pbt: studentData.toefl_pbt ? Number(studentData.toefl_pbt) : undefined,
			toefl_ibt: studentData.toefl_ibt ? Number(studentData.toefl_ibt) : undefined,
			self_assessment_proficiency: studentData.self_assessment_proficiency || undefined,
			proficiency_type: (studentData.proficiency_type as EnglishProficiencyType) || null,
			proficiency_test: (studentData.proficiency_test as EnglishTestType) || null,
		};
	}

	private createEmailAnswer(studentData: IStudent): IEmail {
		return {
			email: studentData.email || '',
		};
	}

	private createEducationalLevelAnswer(studentData: IStudent): IEducationalLevel {
		return {
			study_level: studentData.study_level || '',
		};
	}

	private createDisciplineInterestAnswer(studentData: IStudent): IDisciplineInterests {
		return {
			[StudentField.INTERESTS_DISCIPLINES]: studentData.interests_disciplines || [],
		};
	}

	private createCountryOfResidenceAnswer(studentData: IStudent): ICountryOfResidence {
		return {
			residence_country_id: studentData[StudentField.RESIDENCE_COUNTRY_ID] || null,
		};
	}

	private createCountryInterestAnswer(studentData: IStudent): ICountryInterests {
		return {
			[StudentField.INTERESTS_COUNTRIES]: studentData.interests_countries || [],
		};
	}

	private createBirthDateAnswer(studentData: IStudent): IBirthDate {
		return {
			birth_date: studentData.birth_date || '',
		};
	}

	private createGenderAnswer(studentData: IStudent): IGender {
		return {
			gender: studentData.gender || '',
		};
	}

	private createNameAnswer(studentData: IStudent): IName {
		return {
			name: studentData.name || '',
		};
	}

	private createAttendanceAnswer(studentData: IStudent): IAttendance {
		return {
			attendance: studentData.attendance || null,
		};
	}

	private createCurrencyAnswer(studentData: IStudent): ICurrency {
		return {
			currency: studentData.currency || null,
		};
	}
}
