import { QuestionType } from 'interfaces';
import { IState } from '@/interfaces/store.interfaces';
import { reactive } from 'vue';
import { StudentField } from '@studyportals/studentdomain';
import { DiscountInformation } from '@studyportals/iu-global-access-initiative/interfaces/DiscountInformation';

const state: IState = reactive({
	areDependenciesLoaded: false,
	isReady: false,
	isLoggedIn: false,
	profileCompleteness: 0,
	profileCompletenessFirstUnansweredQuestion: null,
	userCurrency: null,
	userLocalCurrency: null,
	questionnaires: {},
	discountInformations: new Map<string, DiscountInformation>(),
	globalAccessInitiativeQuestionnaireTaken: false,
	iuCountryId: 0,
	currentSelectedStudyPreferenceField: null,
	userNationalityIsoCode: null,
	questions: {
		[QuestionType.BIRTH_DATE]: {
			type: QuestionType.BIRTH_DATE,
			answer: {
				birth_date: '',
			},
		},
		[QuestionType.EDUCATIONAL_LEVEL]: {
			type: QuestionType.EDUCATIONAL_LEVEL,
			answer: {
				study_level: '',
			},
		},
		[QuestionType.EMAIL]: {
			type: QuestionType.EMAIL,
			answer: {
				email: '',
			},
		},
		[QuestionType.INSTITUTE_OF_ORIGIN]: {
			type: QuestionType.INSTITUTE_OF_ORIGIN,
			answer: {
				study_country_id: null,
				origin_organisation_id: 0,
			},
		},
		[QuestionType.FIELD_OF_STUDY]: {
			type: QuestionType.FIELD_OF_STUDY,
			answer: {
				disciplines: [],
			},
		},
		[QuestionType.GENDER]: {
			type: QuestionType.GENDER,
			answer: {
				gender: '',
			},
		},
		[QuestionType.GPA]: {
			type: QuestionType.GPA,
			answer: {
				gpa: {
					current_type: '',
				},
			},
		},
		[QuestionType.WORK_EXPERIENCE]: {
			type: QuestionType.WORK_EXPERIENCE,
			answer: {
				work_experience: '',
			},
		},
		[QuestionType.ENGLISH_SKILLS]: {
			type: QuestionType.ENGLISH_SKILLS,
			answer: {
				proficiency_type: null,
				proficiency_test: null,
				pte: 0,
				toefl_pbt: 0,
				toefl_ibt: 0,
				ielts: 0,
				cae: 0,
				self_assessment_proficiency: '',
			},
		},
		[QuestionType.TUITION_BUDGET]: {
			type: QuestionType.TUITION_BUDGET,
			answer: {
				tuition_budget: {
					amount: null,
					currency: '',
					unit: 'year',
				},
			},
		},
		[QuestionType.LIVING_BUDGET]: {
			type: QuestionType.LIVING_BUDGET,
			answer: {
				living_budget: {
					amount: null,
					currency: '',
					unit: 'month',
				},
			},
		},
		[QuestionType.NATIONALITY]: {
			type: QuestionType.NATIONALITY,
			answer: {
				nationality_country_id: null,
				nationality_country_iso: null,
			},
		},
		[QuestionType.NAME]: {
			type: QuestionType.NAME,
			answer: {
				name: '',
			},
		},
		[QuestionType.DISCIPLINE_INTERESTS]: {
			type: QuestionType.DISCIPLINE_INTERESTS,
			answer: {
				[StudentField.INTERESTS_DISCIPLINES]: [],
			},
		},
		[QuestionType.COUNTRY_INTERESTS]: {
			type: QuestionType.COUNTRY_INTERESTS,
			answer: {
				[StudentField.INTERESTS_COUNTRIES]: [],
			},
		},
		[QuestionType.COUNTRY_OF_RESIDENCE]: {
			type: QuestionType.COUNTRY_OF_RESIDENCE,
			answer: {
				residence_country_id: null,
			},
		},
		[QuestionType.START_PERIOD]: {
			type: QuestionType.START_PERIOD,
			answer: {
				start_period_date: '',
			},
		},
		[QuestionType.ATTENDANCE]: {
			type: QuestionType.ATTENDANCE,
			answer: {
				attendance: null,
			},
		},
		[QuestionType.CURRENCY]: {
			type: QuestionType.CURRENCY,
			answer: {
				currency: null,
			},
		},
	},
});

export default state;
