export enum StudyPreferencesEventTrackType {
	CONFIRM_REGISTRATION = 'confirm_registration',
	EDIT = 'edit',
	CLOSE = 'close',
}

export enum StudyPreferencesAttendanceType {
	WITH_ATTENDANCE = 'with_attendance',
	WITHOUT_ATTENDANCE = 'without_attendance',
}
