/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { NavigationDirections } from '@/domain/enums/navigation-directions';
import { NavigationStates } from '@/domain/enums/navigation-states';
import { Answer, QuestionDTO } from '@/interfaces/answer.interface';
import state from './state';
import { IMutations } from '@/interfaces/store.interfaces';
import { IQuestionnaireSignUp } from '@/interfaces/questionnaire-sign-up.interface';
import Step from '@/presentation/components/Questionnaire/Step/Step.class';
import { QuestionnaireType, QuestionType } from 'interfaces';
import { StudyPreferencesFieldType } from '@/interfaces/enums/study-preferences-filed-type';

const mutations: IMutations = {
	setAreDependenciesLoaded: (areDependenciesLoaded: boolean): void => {
		state.areDependenciesLoaded = areDependenciesLoaded;
	},

	setIsReady: (isReady: boolean) => {
		state.isReady = isReady;
	},

	setLoggedInFlag: (isLoggedIn: boolean) => {
		state.isLoggedIn = isLoggedIn;
	},

	saveAnswer: (answer: QuestionDTO<Answer>) => {
		state.questions = {
			...state.questions,
			[answer.type]: {
				type: answer.type,
				answer: answer.answer,
			},
		};
	},

	updateQuestions: (updatedQuestions: QuestionDTO<Answer>[]) => {
		state.questions = {
			...state.questions,
			...updatedQuestions,
		};
	},

	signUpQuestionnaire: ({ identifier, type, index, requestedSteps, onlyFinishOnSignIn }: IQuestionnaireSignUp) => {
		state.questionnaires = {
			...state.questionnaires,
			[identifier]: {
				type,
				index,
				requestedSteps,
				steps: [],
				direction: NavigationDirections.FORWARD,
				isFinished: false,
				navigationState: NavigationStates.ENABLED,
				onlyFinishOnSignIn,
			},
		};
	},

	signUpStep: (step: Step) => {
		const identifier: string = step.questionnaireIdentifier.value;
		if (!(identifier in state.questionnaires)) {
			return;
		}

		const questionnaireCopy: any = { ...state.questionnaires };

		if (questionnaireCopy[identifier].requestedSteps.length <= 0) {
			questionnaireCopy[identifier].steps.push({
				key: step.componentIdentifer,
				show: step.show,
				label: step.label,
				darkBackground: step.darkBackground,
			});

			state.questionnaires = questionnaireCopy;
			return;
		}

		if (!questionnaireCopy[identifier].requestedSteps.includes(step.label) && !step.required) {
			return;
		}

		questionnaireCopy[identifier].steps.push({
			key: step.componentIdentifer,
			show: step.show,
			label: step.label,
			darkBackground: step.darkBackground,
		});

		state.questionnaires = questionnaireCopy;
	},

	nextQuestion: (identifier: string) => {
		state.questionnaires[identifier] = {
			...state.questionnaires[identifier],
			index: state.questionnaires[identifier].index + 1,
		};
	},

	previousQuestion: (identifier: string) => {
		state.questionnaires[identifier] = {
			...state.questionnaires[identifier],
			index: state.questionnaires[identifier].index - 1,
		};
	},

	setIndex: ({ identifier, index }: { identifier: string; index: number }) => {
		state.questionnaires[identifier] = {
			...state.questionnaires[identifier],
			index,
		};
	},

	setDirection: ({ identifier, direction }: { identifier: string; direction: NavigationDirections }) => {
		state.questionnaires[identifier] = {
			...state.questionnaires[identifier],
			direction,
		};
	},

	updateStepVisibility: ({ identifier, key, show }: { identifier: string; key: string; show: boolean }) => {
		let questionKey = -1;
		state.questionnaires[identifier]?.steps?.forEach((step, index) => {
			if (key === step.key) {
				questionKey = index;
			}
		});

		if (questionKey === -1) {
			return;
		}
		const steps = state.questionnaires[identifier].steps;
		if (!steps) {
			return;
		}
		steps[questionKey].show = show;
	},

	setQuestionnaireFinishedFlag: ({ identifier, flag }: { identifier: string; flag: boolean }) => {
		state.questionnaires[identifier].isFinished = flag;
	},

	setNavigationState: ({ identifier, navigationState }: { identifier: string; navigationState: NavigationStates }) => {
		state.questionnaires[identifier] = {
			...state.questionnaires[identifier],
			navigationState,
		};
	},

	signOffQuestionnaire: (identifier: QuestionnaireType) => {
		delete state.questionnaires[identifier];
	},

	setProfileCompleteness: (completeness: number) => {
		state.profileCompleteness = completeness;
	},

	setProfileCompletenessFirstUnansweredQuestion: (questionType: QuestionType | null) => {
		state.profileCompletenessFirstUnansweredQuestion = questionType;
	},

	setUserCurrency: (currency: string) => {
		state.userCurrency = currency;
	},
	setUserLocalCurrency: (currency: string) => {
		state.userLocalCurrency = currency;
	},
	setIuCountryId: (countryId: number) => {
		state.iuCountryId = countryId;
	},

	setGlobalAccessInitiativeQuestionnaireTaken: (flag: boolean) => {
		state.globalAccessInitiativeQuestionnaireTaken = flag;
	},

	setCurrentSelectedStudyPreferenceField: (field: StudyPreferencesFieldType | null) => {
		state.currentSelectedStudyPreferenceField = field;
	},

	setUserNationalityIsoCode: (isoCode: string) => {
		state.userNationalityIsoCode = isoCode;
	},
};

export default mutations;
