import store from '@/store';
import {
	AnonymousStudentProfileUpdated,
	AnonymousStudentServiceReady,
	AnonymousStudentProfileSynced,
	IAnonymousStudentService,
	StudentRepositoryStateType,
} from '@studyportals/anonymous-student-interfaces';
import { ISubscriber, IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import { StudentField } from '@studyportals/studentdomain';

export class UICountryIdClient implements ISubscriber<AnonymousStudentServiceReady> {
	private client?: IAnonymousStudentService;
	private countryId: number | undefined;

	public startListening(): void {
		this.eventAggregationService().subscribeTo(AnonymousStudentServiceReady.EventType, this, true);
		this.eventAggregationService().subscribeTo(AnonymousStudentProfileUpdated.EventType, this, false);
		this.eventAggregationService().subscribeTo(AnonymousStudentProfileSynced.EventType, this, false);
	}

	public async notify(event: AnonymousStudentServiceReady | AnonymousStudentProfileUpdated): Promise<void> {
		if (event.eventType === AnonymousStudentServiceReady.EventType) {
			await this.handleAnonymousStudentServiceReady(event as AnonymousStudentServiceReady);
		}

		if (event.eventType === AnonymousStudentProfileUpdated.EventType) {
			this.handleAnonymousStudentProfileUpdated(event as AnonymousStudentProfileUpdated);
		}

		if (event.eventType === AnonymousStudentProfileSynced.EventType) {
			await this.handleAnonymousStudentProfileSynced(event as AnonymousStudentProfileSynced);
		}
	}
	private async handleAnonymousStudentServiceReady(event: AnonymousStudentServiceReady): Promise<void> {
		this.client = event.anonymousStudentService;

		this.countryId = await this.getData();
		if (this.countryId !== undefined) {
			store.mutations.setIuCountryId(this.countryId);
		}
	}

	private async handleAnonymousStudentProfileSynced(event: AnonymousStudentProfileSynced): Promise<void> {
		if (event.state !== StudentRepositoryStateType.ONLINE) {
			return;
		}

		this.countryId = await this.getData();
		if (this.countryId !== undefined) {
			store.mutations.setIuCountryId(this.countryId);
		}
	}

	private handleAnonymousStudentProfileUpdated(event: AnonymousStudentProfileUpdated): void {
		this.countryId = event.changes.nationality_country_id;
		if (event.state === StudentRepositoryStateType.ONLINE) {
			this.countryId = event.changes.residence_country_id;
		}

		if (this.countryId) {
			store.mutations.setIuCountryId(this.countryId);
		}
	}

	private async getData(): Promise<number | undefined> {
		if (!this.client) {
			return undefined;
		}

		try {
			const student = await this.client.getStudentData([StudentField.RESIDENCE_COUNTRY_ID, StudentField.NATIONALITY_COUNTRY_ID]);
			const residenceCountryId = student[StudentField.RESIDENCE_COUNTRY_ID];
			const nationalityCountryId = student[StudentField.NATIONALITY_COUNTRY_ID];
			if (residenceCountryId) {
				return residenceCountryId;
			}
			if (nationalityCountryId) {
				return nationalityCountryId;
			}

			return undefined;
		} catch (e) {
			console.warn('failed to retrieve country of residence');
		}
	}

	private eventAggregationService(): IEventAggregationService {
		return window['EventAggregationService'] as IEventAggregationService;
	}
}
