import { ISessionService } from '@studyportals/student-interfaces';
import { SessionAdapter } from '../../domain/session-adapter';
import globals from '@/utils/globals';
import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
export class LoginStatusCheckerPlugin {
	public async initialize(): Promise<void> {
		const eventAggregationService: IEventAggregationService = globals.eventAggregationService;
		const sessionService: ISessionService = globals.sessionService;

		const sessionAdapter = this.createSessionAdapter(sessionService, eventAggregationService);
		await sessionAdapter.initialize();
	}

	// tslint:disable-next-line:max-line-length
	private createSessionAdapter(sessionService: ISessionService, eventAggregationService: IEventAggregationService): SessionAdapter {
		return new SessionAdapter(sessionService, eventAggregationService);
	}
}
