import ITrackingObject from '../interfaces/tracking-object.interface';

export default class Tracking {
	/**
	 * @deprecated Please use SnowplowTracker instead
	 */
	public snowPlow(trackingParams: ITrackingObject): void | null {
		if (typeof window['snowplow'] === 'undefined') {
			return null;
		}

		window['snowplow']('trackStructEvent', {
			category: trackingParams.category,
			action: trackingParams.action,
			label: trackingParams.label || '',
			property: trackingParams.property || '',
			value: trackingParams.value || 0,
		});
	}
}
