import { IEvent, IEventAggregationService, ISubscriber } from '@studyportals/event-aggregation-service-interface';

export class EventAggregationService implements IEventAggregationService {
	private get target(): IEventAggregationService | undefined | null {
		return window['EventAggregationService'];
	}

	public subscribeTo(eventType: string, subscriber: ISubscriber<IEvent>, replay?: boolean | undefined): void {
		if (this.target) {
			this.target.subscribeTo(eventType, subscriber, replay);
		}
	}

	public publishTo(eventType: string, event: IEvent): void {
		if (this.target) {
			this.target.publishTo(eventType, event);
		}
	}
}
