import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BestFitSearch = _resolveComponent("BestFitSearch")!
  const _component_GlobalAccessInitiativeBanner = _resolveComponent("GlobalAccessInitiativeBanner")!
  const _component_GlobalAccessInitiativeLabel = _resolveComponent("GlobalAccessInitiativeLabel")!
  const _component_SearchGlobalAccessInitiativeLabel = _resolveComponent("SearchGlobalAccessInitiativeLabel")!
  const _component_ScholarshipIncentiveLabel = _resolveComponent("ScholarshipIncentiveLabel")!
  const _component_SearchScholarshipIncentiveLabel = _resolveComponent("SearchScholarshipIncentiveLabel")!
  const _component_BestFit = _resolveComponent("BestFit")!
  const _component_LocalizationSettings = _resolveComponent("LocalizationSettings")!
  const _component_ProfilePage = _resolveComponent("ProfilePage")!
  const _component_Recommendations = _resolveComponent("Recommendations")!
  const _component_Onboarding = _resolveComponent("Onboarding")!
  const _component_GlobalAccessInitiative = _resolveComponent("GlobalAccessInitiative")!
  const _component_StartDate = _resolveComponent("StartDate")!
  const _component_ScholarshipsEligibility = _resolveComponent("ScholarshipsEligibility")!
  const _component_Discover = _resolveComponent("Discover")!
  const _component_StudyPreferences = _resolveComponent("StudyPreferences")!

  return (_ctx.isReady)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showIncentive)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.incentiveType === _ctx.incentives.BESTFIT_SEARCH)
                ? (_openBlock(), _createBlock(_component_BestFitSearch, {
                    key: 0,
                    incentive_identifier: _ctx.incentive_identifier
                  }, null, 8 /* PROPS */, ["incentive_identifier"]))
                : _createCommentVNode("v-if", true),
              (_ctx.incentiveType === _ctx.incentives.GLOBAL_ACCESS_INITIATIVE_BANNER)
                ? (_openBlock(), _createBlock(_component_GlobalAccessInitiativeBanner, {
                    key: 1,
                    data: _ctx.data
                  }, null, 8 /* PROPS */, ["data"]))
                : _createCommentVNode("v-if", true),
              (_ctx.incentiveType === _ctx.incentives.GLOBAL_ACCESS_INITIATIVE_LABEL)
                ? (_openBlock(), _createBlock(_component_GlobalAccessInitiativeLabel, {
                    key: 2,
                    data: _ctx.data
                  }, null, 8 /* PROPS */, ["data"]))
                : _createCommentVNode("v-if", true),
              (_ctx.incentiveType === _ctx.incentives.SEARCH_GLOBAL_ACCESS_INITIATIVE_LABEL)
                ? (_openBlock(), _createBlock(_component_SearchGlobalAccessInitiativeLabel, {
                    key: 3,
                    data: _ctx.data
                  }, null, 8 /* PROPS */, ["data"]))
                : _createCommentVNode("v-if", true),
              (_ctx.incentiveType === _ctx.incentives.SCHOLARSHIP_INCENTIVE_LABEL)
                ? (_openBlock(), _createBlock(_component_ScholarshipIncentiveLabel, {
                    key: 4,
                    data: _ctx.data
                  }, null, 8 /* PROPS */, ["data"]))
                : _createCommentVNode("v-if", true),
              (_ctx.incentiveType === _ctx.incentives.SEARCH_SCHOLARSHIP_INCENTIVE_LABEL)
                ? (_openBlock(), _createBlock(_component_SearchScholarshipIncentiveLabel, {
                    key: 5,
                    data: _ctx.data
                  }, null, 8 /* PROPS */, ["data"]))
                : _createCommentVNode("v-if", true)
            ]))
          : (_ctx.showQuestionnaire)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.questionnaireType === _ctx.questionnaires.BESTFIT)
                  ? (_openBlock(), _createBlock(_component_BestFit, {
                      key: 0,
                      index: _ctx.questionnaireIndexNumber,
                      steps: _ctx.steps
                    }, null, 8 /* PROPS */, ["index", "steps"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.questionnaireType === _ctx.questionnaires.LOCALIZATION_SETTINGS)
                  ? (_openBlock(), _createBlock(_component_LocalizationSettings, {
                      key: 1,
                      steps: _ctx.steps
                    }, null, 8 /* PROPS */, ["steps"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.questionnaireType === _ctx.questionnaires.PROFILE_PAGE)
                  ? (_openBlock(), _createBlock(_component_ProfilePage, {
                      key: 2,
                      steps: _ctx.steps
                    }, null, 8 /* PROPS */, ["steps"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.questionnaireType === _ctx.questionnaires.RECOMMENDATIONS)
                  ? (_openBlock(), _createBlock(_component_Recommendations, {
                      key: 3,
                      steps: _ctx.steps
                    }, null, 8 /* PROPS */, ["steps"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.questionnaireType === _ctx.questionnaires.ONBOARDING)
                  ? (_openBlock(), _createBlock(_component_Onboarding, {
                      key: 4,
                      steps: _ctx.steps
                    }, null, 8 /* PROPS */, ["steps"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.questionnaireType === _ctx.questionnaires.GLOBAL_ACCESS_INITIATIVE)
                  ? (_openBlock(), _createBlock(_component_GlobalAccessInitiative, {
                      key: 5,
                      steps: _ctx.steps,
                      data: _ctx.data
                    }, null, 8 /* PROPS */, ["steps", "data"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.questionnaireType === _ctx.questionnaires.START_DATE)
                  ? (_openBlock(), _createBlock(_component_StartDate, {
                      key: 6,
                      steps: _ctx.steps
                    }, null, 8 /* PROPS */, ["steps"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.questionnaireType === _ctx.questionnaires.SCHOLARSHIPS_ELIGIBILITY)
                  ? (_openBlock(), _createBlock(_component_ScholarshipsEligibility, {
                      key: 7,
                      steps: _ctx.steps
                    }, null, 8 /* PROPS */, ["steps"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.questionnaireType === _ctx.questionnaires.FJS_DISCOVER)
                  ? (_openBlock(), _createBlock(_component_Discover, {
                      key: 8,
                      steps: _ctx.steps
                    }, null, 8 /* PROPS */, ["steps"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.questionnaireType === _ctx.questionnaires.STUDY_PREFERENCES)
                  ? (_openBlock(), _createBlock(_component_StudyPreferences, { key: 9 }))
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}