export enum Incentives {
	BESTFIT_SEARCH = 'bestfit_search',
	GLOBAL_ACCESS_INITIATIVE_BANNER = 'global_access_initiative_banner',
	GLOBAL_ACCESS_INITIATIVE_LABEL = 'global_access_initiative_label',
	SEARCH_GLOBAL_ACCESS_INITIATIVE_LABEL = 'search_global_access_initiative_label',
	SCHOLARSHIP_INCENTIVE_LABEL = 'scholarship_incentive_label',
	SEARCH_SCHOLARSHIP_INCENTIVE_LABEL = 'search_scholarship_incentive_label',
	ONBOARDING_NOTIFICATIONS_INCENTIVE = 'onboarding_notifications_incentive',
	ONBOARDING_INCENTIVE = 'onboarding_incentive',
	LOCALIZATION_SETTINGS_INCENTIVE = 'localization_settings_incentive',
}
