import { IStudent, StudentField } from '@studyportals/studentdomain';
import { QuestionType } from '../../../interfaces';
import { StudentDatabaseClient } from '@/infrastructure/student-database-client';
import { Answer, QuestionDTO } from '@/interfaces/answer.interface';
import { AnswerFactory } from '../factories/answer-factory';

export class StudentRepository {
	private studentDatabaseClient: StudentDatabaseClient;
	private answerFactory: AnswerFactory;

	constructor(studentDatabaseClient: StudentDatabaseClient) {
		this.studentDatabaseClient = studentDatabaseClient;
		this.answerFactory = new AnswerFactory();
	}

	public async update(question: QuestionDTO<Answer>): Promise<void> {
		try {
			await this.studentDatabaseClient.saveSingleAnswer(question);
		} catch (e: any) {
			const message: string = e.message;
			throw new Error('Profile-Questionnaires update student data failed!: ' + message);
		}
	}

	public async retrieveQuestions(questionTypes: QuestionType[]): Promise<QuestionDTO<Answer>[]> {
		const studentFields: StudentField[] = this.getStudentFieldsByQuestions(questionTypes);
		const studentData = await this.studentDatabaseClient.retrieveStudentData(studentFields);

		return questionTypes.map((type) => {
			return {
				type,
				answer: this.answerFactory.createAnswerFromStudentData(type, studentData),
			};
		});
	}

	public async getNationalityCountryId(): Promise<number | undefined> {
		const data = await this.getStudentData([StudentField.NATIONALITY_COUNTRY_ID]);
		return data[StudentField.NATIONALITY_COUNTRY_ID];
	}

	public async getNationalityCountryISO(): Promise<string | undefined> {
		const data = await this.getStudentData([StudentField.NATIONALITY_COUNTRY_ISO]);
		return data[StudentField.NATIONALITY_COUNTRY_ISO];
	}

	public async getStudentCurrency(): Promise<string | undefined> {
		const data = await this.getStudentData([StudentField.CURRENCY]);
		return data[StudentField.CURRENCY];
	}

	public getStudentData(fields: StudentField[]): Promise<IStudent> {
		return this.studentDatabaseClient.retrieveStudentData(fields);
	}

	public translateQuestionTypeToStudentFields(type: QuestionType): StudentField[] {
		switch (type) {
			case QuestionType.BIRTH_DATE:
				return [StudentField.BIRTH_DATE];
			case QuestionType.COUNTRY_INTERESTS:
				return [StudentField.INTERESTS_COUNTRIES];
			case QuestionType.COUNTRY_OF_RESIDENCE:
				return [StudentField.RESIDENCE_COUNTRY_ID];
			case QuestionType.DISCIPLINE_INTERESTS:
				return [StudentField.INTERESTS_DISCIPLINES];
			case QuestionType.EDUCATIONAL_LEVEL:
				return [StudentField.STUDY_LEVEL];
			case QuestionType.EMAIL:
				return [StudentField.EMAIL];
			case QuestionType.ENGLISH_SKILLS:
				return [
					StudentField.PROFICIENCY_TYPE,
					StudentField.PROFICIENCY_TEST,
					StudentField.TOEFL_PBT,
					StudentField.TOEFL_IBT,
					StudentField.IELTS,
					StudentField.CAE,
					StudentField.SELF_ASSESSMENT_PROFICIENCY,
					StudentField.PTE,
				];
			case QuestionType.FIELD_OF_STUDY:
				return [StudentField.DISCIPLINES];
			case QuestionType.GENDER:
				return [StudentField.GENDER];
			case QuestionType.GPA:
				return [StudentField.GPA];
			case QuestionType.INSTITUTE_OF_ORIGIN:
				return [StudentField.ORIGIN_ORGANISATION_ID, StudentField.STUDY_COUNTRY_ID];
			case QuestionType.LIVING_BUDGET:
				return [StudentField.LIVING_BUDGET];
			case QuestionType.NAME:
				return [StudentField.NAME];
			case QuestionType.NATIONALITY:
				return [StudentField.NATIONALITY_COUNTRY_ID, StudentField.NATIONALITY_COUNTRY_ISO];
			case QuestionType.START_PERIOD:
				return [StudentField.START_PERIOD_DATE];
			case QuestionType.TELEPHONE:
				return [StudentField.TELEPHONE];
			case QuestionType.TUITION_BUDGET:
				return [StudentField.TUITION_BUDGET];
			case QuestionType.ATTENDANCE:
				return [StudentField.ATTENDANCE];
			case QuestionType.WORK_EXPERIENCE:
				return [StudentField.WORK_EXPERIENCE];
			case QuestionType.FIND_BACHELORS:
			case QuestionType.SAVE_PROGRESS:
			case QuestionType.INTRODUCTION:
				return [];
			case QuestionType.CURRENCY:
				return [StudentField.CURRENCY];
		}
	}

	private getStudentFieldsByQuestions(questionTypes: QuestionType[]): StudentField[] {
		let studentFields: StudentField[] = [];
		for (const type of questionTypes) {
			const questionFields = this.translateQuestionTypeToStudentFields(type);
			studentFields = studentFields.concat(questionFields);
		}

		return studentFields;
	}
}
