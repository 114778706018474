import { Incentives } from '../enums/incentives';

export class RenderQuestionnaireIncentiveCommand extends CustomEvent<RenderQuestionnaireIncentiveRequest> {
	constructor(identifier: Incentives) {
		const detail: RenderQuestionnaireIncentiveRequest = {
			identifier,
		};
		super('', { detail });
	}
}

interface RenderQuestionnaireIncentiveRequest {
	identifier: Incentives;
}
