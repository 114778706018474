/* eslint-disable @typescript-eslint/no-unsafe-call */
export default class HotJarTracker {
	/**
	 * @deprecated Please use trackHotjarEvent instead
	 */
	public tagHotjarRecording(tags: string[]): void {
		if (typeof window['hj'] === 'undefined') {
			return;
		}
		if (!tags || !tags.length) return;
		// Add a namespace to the tags and send them to Hotjar
		window['hj'](
			'tagRecording',
			tags.map((tag) => `ProfileQuestionnares - ${tag}`),
		);
	}

	public trackHotjarEvent(action_name: string): void {
		if (!action_name) return;
		if (typeof window['hj'] === 'undefined') {
			return;
		}

		window['hj']('event', `ProfileQuestionnares - ${action_name}`);
	}
}
