import { IEvent } from '@studyportals/event-aggregation-service-interface';
import { EventType, QuestionType, QuestionnaireType } from '../enums';

export class ProfileQuestionnairesAnswerChangedEvent implements IEvent {
	public static EventType: string = EventType.PROFILE_QUESTIONNAIRES_ANSWER_CHANGED_EVENT;

	public readonly timestamp: Date;
	public readonly eventType: string = ProfileQuestionnairesAnswerChangedEvent.EventType;

	public readonly identifier: QuestionnaireType;
	public readonly question: QuestionType;
	public readonly isValid: boolean;

	public constructor(identifier: QuestionnaireType, question: QuestionType, isValid: boolean) {
		this.timestamp = new Date();
		this.identifier = identifier;
		this.question = question;
		this.isValid = isValid;
	}
}
