import { IAnonymousStudentService } from '@studyportals/anonymous-student-interfaces';
import { StudentField } from '@studyportals/studentdomain';
import { QuestionType } from '../../../interfaces';

export class ProfileCompletenessRepository {
	private studentFields: StudentField[];
	private anonymousStudentService: IAnonymousStudentService;

	constructor(anonymousStudentService: IAnonymousStudentService) {
		this.anonymousStudentService = anonymousStudentService;
		this.studentFields = [
			StudentField.STUDY_LEVEL,
			StudentField.STUDY_COUNTRY_ID,
			StudentField.DISCIPLINES,
			StudentField.GPA,
			StudentField.WORK_EXPERIENCE,
			StudentField.PROFICIENCY_TYPE,
			StudentField.TUITION_BUDGET,
			StudentField.LIVING_BUDGET,
		];
	}

	public async getCompleteness(): Promise<number> {
		return Math.ceil(((await this.anonymousStudentService.getStudentDataCompleteness(this.studentFields)) ?? 0) * 100);
	}

	public async getFirstIncompleteQuestion(): Promise<QuestionType | null> {
		const studentData = await this.anonymousStudentService.getStudentData(this.studentFields);
		let result: QuestionType | null = null;

		for (const field in studentData) {
			if (typeof studentData[field] === 'undefined') {
				result = this.getQuestionTypeFromField(field);
				break;
			}
		}

		return result;
	}

	private getQuestionTypeFromField(field: string): QuestionType | null {
		switch (field) {
			case StudentField.TUITION_BUDGET:
				return QuestionType.TUITION_BUDGET;
			case StudentField.LIVING_BUDGET:
				return QuestionType.LIVING_BUDGET;
			case StudentField.STUDY_LEVEL:
				return QuestionType.EDUCATIONAL_LEVEL;
			case StudentField.STUDY_COUNTRY_ID:
				return QuestionType.INSTITUTE_OF_ORIGIN;
			case StudentField.DISCIPLINES:
				return QuestionType.FIELD_OF_STUDY;
			case StudentField.GPA:
				return QuestionType.GPA;
			case StudentField.WORK_EXPERIENCE:
				return QuestionType.WORK_EXPERIENCE;
			case StudentField.PROFICIENCY_TYPE:
				return QuestionType.ENGLISH_SKILLS;
			default:
				return null;
		}
	}
}
